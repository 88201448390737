/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { useCallback, useMemo, useSyncExternalStore } from "react";
// Import zod.
import z from "zod";
// Import the JSON:API resource identifier.
import { ResourceIdentifier } from "@andromeda/json-api";

// Import the database loader.
import { getDatabase } from "./database";


/** Schema used to validate the database state. */
const FavouriteList: z.ZodArray<z.ZodType<ResourceIdentifier>> = z.object({ type: z.string(), id: z.string() }).array();

/** List of all the elements that were favourited. */
let favourites: z.infer<typeof FavouriteList> = [];

// Load the favourites from the database.
getDatabase().then(function loadFavouritesFromDatabase(database: IDBDatabase): void {
    // Get the currently selected organisation.
    const currentOrg = window.localStorage.getItem("com.zaqtiv.current-org");
    if (!currentOrg) {
        return;
    }

    const request = database
        .transaction("favourite", "readonly")
        .objectStore("favourite")
        .get(currentOrg);

    request.addEventListener("success", function onCursorReady(): void {
        // Parse the response.
        const schema = z.object({
            key: z.string().default(currentOrg),
            value: FavouriteList.default([])
        }).optional().default({ key: currentOrg, value: [] });
        favourites = schema.parse(request.result).value;

        // Invoke all the callbacks.
        callbacks.forEach(callback => callback(favourites));
    });
});

// List of callbacks registered.
type FavouriteCallback = (state: z.infer<typeof FavouriteList>) => void;
const callbacks: FavouriteCallback[] = [];

/** {@link useSyncExternalStore}-compatible subscription tool. */
export function subscribeToFavourites(callback: FavouriteCallback): VoidFunction {
    callbacks.push(callback);
    return function unsubscribeFromFavourites(): void {
        const index = callbacks.indexOf(callback);
        if (index >= 0) {
            callbacks.splice(index, 1);
        }
    };
}

/** {@link useSyncExternalStore}-compatible state tool. */
function getState(): z.infer<typeof FavouriteList> {
    return favourites;
}

/**
 * Hook used to get a callback used to check the favourite state of a given resource.
 *
 * @returns {(type: string, id: string) => boolean} A callback that checks if a given resource was favourited.
 */
export function useGetFavouriteState(): (type: string, id: string) => boolean {
    // Get the favourites map.
    const map = useSyncExternalStore(subscribeToFavourites, getState);

    // Return the callback.
    return useCallback(function isFavourite(type: string, id: string): boolean {
        return map.some(item => item.type === type && item.id === id);
    }, [map]);
}

/** Hook used to check if an item was favourited. */
export function useIsFavourite(type: string, id?: string): boolean {
    const getFavouriteState = useGetFavouriteState();
    return useMemo(() => !!id && getFavouriteState(type, id), [getFavouriteState, id, type]);
}

/** Hook used to retrieve the list of all the favourite items. */
export function useFavouriteList(): ResourceIdentifier[] {
    // Get the map of all the favourites.
    return useSyncExternalStore(subscribeToFavourites, getState);
}

/**
 * Callback used to toggle the favourite status of a given element.
 *
 * @param {string} type The type of the favourited resource.
 * @param {string} id The id of the favourited resource.
 * If omitted, the value will be toggled.
 */
export function toggleFavourite(type: string, id: string, forcedState?: boolean): Promise<void> {
    // Get the currently selected organisation.
    const currentOrg = window.localStorage.getItem("com.zaqtiv.current-org");
    if (!currentOrg) {
        return Promise.reject(new TypeError("No selected organisation !"));
    }

    // Toggle the status of the resource.
    const valueIndex = favourites.findIndex(item => item.type === type && item.id === id);
    if (valueIndex < 0 && forcedState !== false) {
        favourites.push({ type, id });
    } else if (forcedState !== true) {
        favourites.splice(valueIndex, 1);
    }
    favourites = [...favourites];

    // Invoke all the callbacks.
    callbacks.forEach(callback => callback(favourites));

    // Serialise the map and save it to the database.
    return getDatabase().then(function serialiseFavourites(database: IDBDatabase): Promise<void> {
        const request = database
            .transaction("favourite", "readwrite")
            .objectStore("favourite")
            .put({ key: currentOrg, value: favourites });

        return new Promise<void>((resolve, reject) => {
            request.addEventListener("success", () => resolve());
            request.addEventListener(
                "error",
                error => reject(new Error("Failed to serialize favourites to IndexedDB", { cause: error }))
            );
        });
    });
}
