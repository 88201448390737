/*
 * Copyright © 2023 - Zimproov.
 * All rights reserved.
 */

// Import React.
import { ReactElement, useEffect } from "react";
// Import the CSS classname helper.
import classNames from "classnames";
// Import the font-awesome icon component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import the JSON:API module.
import type { ResourceIdentifier } from "@andromeda/json-api";

// Import the hooks.
import { SearchItemType } from "../../../hooks";
// Import the storage tools.
import { toggleFavourite, useIsFavourite } from "../../../storage";

// Import the icons.
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar as faStarFilled } from "@fortawesome/free-solid-svg-icons/faStar";
// Import the css.
import css from "./favourite.module.scss";


/** Component used to toggle an item's favourite state. */
export default function ItemFavourite(props: ItemFavouriteProps): ReactElement {
    // Check if the item was added to the favourites.
    const isFavourite = useIsFavourite(props.resource.type, props.resource.id);
    const isLegacyFavourite = useIsFavourite(props.resource.type, props.resource.meta?.["legacy-id"] as string);
    const isActive = isFavourite || isLegacyFavourite;

    // Map legacy favourites to new favourites.
    useEffect(function() {
        // Do nothing if the item was not favourited.
        if (!isLegacyFavourite) {
            return;
        }

        // Toggle the flag.
        toggleFavourite(props.resource.type, props.resource.meta?.["legacy-id"] as string, false);
        toggleFavourite(props.resource.type, props.resource.id, true);
    }, [isLegacyFavourite, props.resource.id, props.resource.meta, props.resource.type]);

    // Build the class name of the button.
    const className = classNames(
        css["button"],
        { [css["button--active"]]: isActive },
        props.className
    );

    // Render the favourite button.
    return <button className={className} onClick={() => toggleFavourite(props.resource.type, props.resource.id)}>
        <FontAwesomeIcon className={css["icon"]} icon={isActive ? faStarFilled : faStarOutline} />
    </button>;
}

/** Props passed down to the {@link ItemFavourite} component. */
export interface ItemFavouriteProps {
    /** The resource that should be favourited/unfavourited. */
    resource: ResourceIdentifier<SearchItemType>;
    /** An optional class name added to the <button> element. */
    className?: string;
}
